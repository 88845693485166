<template>
  <el-dialog
      class="details_dialog"
      :title="$t('robot.transaction_details')"
      :visible.sync="detailsIsShow"
      width="1100px"
      style="height: auto;overflow: unset;padding-top: 0px">
    <el-divider class="details_pc" style="margin-top: -20px !important;"></el-divider>

    <div class="">
      <div style="font-size: 14px;display: flex; justify-content: space-between">
        <span style="display: inline-block;text-align: left;width: 8%;">{{ $t('robot.serial_number') }}</span>
        <span style="display: inline-block;text-align: left;width: 13%;">{{ $t('robot.entrusted_time') }}</span>
        <span style="display: inline-block;text-align: left;width: 13.5%;">{{ $t('robot.transaction_time') }}</span>
        <span style="display: inline-block;text-align: left;width: 6%;">{{ $t('robot.trading_direction') }}</span>
        <span style="display: inline-block;text-align: left;width: 13.5%;">{{ $t('robot.transaction_volume') }}</span>
        <span style="display: inline-block;text-align: left;width: 15%;">{{ $t('robot.average_transaction_price') }}</span>
        <span style="display: inline-block;text-align: left;width: 13.5%;">{{ $t('robot.number_of_transactions') }}</span>
        <span style="display: inline-block;text-align: left;width: 13.5%;">{{ $t('robot.service_charge') }}</span>
      </div>
      <div style="margin-top: 8px">
        <table class="table" style="width: 100%; display: inline-block; border-color:#EBEEF5"frame=void>
          <tbody style="width: 100%; display: inline-table;">
          <tr v-for="(item, i) in DealOrderDetails" style="width: 100%;">
            <td style="text-align: left;width: 8%;word-break: break-word">{{ item.serial_number }}</td>
            <td style="text-align: left;width: 13%;word-break: break-word">{{ item.create_time }}</td>
            <td style="text-align: left;width: 13.5%;word-break: break-word">{{ item.deal_time }}</td>
            <td v-if="item.side == 'buy'" style="text-align: left;width: 6%;color: #52C41A">{{ $t('robot.buy_in') }}</td>
            <td v-if="item.side == 'sell'"style="text-align: left;width: 6%;color: #FF4D4F">{{ $t('robot.sell_out') }}</td>
            <td style="text-align: left;width: 13.5%;word-break: break-word">{{ item.total_true }} <span style="color: #C7C9CB">({{item.coinOne}})</span></td>
            <td style="text-align: left;width: 15%;word-break: break-word">{{ item.avg_price }} <span style="color: #C7C9CB">({{item.coinOne}})</span></td>
            <td style="text-align: left;width: 13.5%;word-break: break-word">{{ item.size }} <span style="color: #C7C9CB">({{item.coinTwo}})</span></td>
            <td style="text-align: left;width: 13.5%;word-break: break-word">-{{ item.fee_true }} <span style="color: #C7C9CB">({{item.side == 'buy'?item.coinTwo:item.coinOne}})</span></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </el-dialog>
</template>

<script>

import {getDealOrderDetails, getMartinDealOrderDetails} from "../../server/robot";

export default {
  name: "martin_deal_details",
  data(){
    return{
      detailsIsShow: false,
      items: {},
      DealOrderDetails: []
    }
  },

  mounted() {
  },

  methods:{
    dialogItem(detailsIsShow, item) {
      this.detailsIsShow = detailsIsShow
      this.items = item;
      this.showDetails()
    },

    async showDetails(){
      this.DealOrderDetails = []
      const res = await getMartinDealOrderDetails(this.items.strategy_uid,this.items.multiplex,this.items.number_of_cycles);
      if (!res) return
      if (res.success){
        if (!res.data) return
        this.DealOrderDetails = res.data
        for (let i=0; i<this.DealOrderDetails.length; i++){
          if (this.DealOrderDetails[i].side == 'sell'){
            this.DealOrderDetails[i].charge = (this.DealOrderDetails[i].price * this.DealOrderDetails[i].size * 0.0008).toFixed(8)
          } else {
            this.DealOrderDetails[i].charge = (this.DealOrderDetails[i].size * 0.0008).toFixed(8)
          }
          if(this.DealOrderDetails[i].side == 'buy'){
            if (this.DealOrderDetails[i].robot_id == 0){
              this.DealOrderDetails[i].serial_number = this.$t('robot.one_click_replenishment')
            } else if (this.DealOrderDetails[i].robot_id == 1){
              this.DealOrderDetails[i].serial_number = this.$t('robot.first_order')
            } else {
              this.DealOrderDetails[i].serial_number = this.$t('robot.di')+ (parseInt(this.DealOrderDetails[i].robot_id) - 1) + this.$t('robot.ci') + this.$t('robot.replenishment')
            }
          } else if(this.DealOrderDetails[i].side == 'sell'){
            if (this.DealOrderDetails[i].is_stop_surplus == 0){
              this.DealOrderDetails[i].serial_number = this.$t('robot.interference')
            } else if (this.DealOrderDetails[i].is_stop_surplus == 1){
              this.DealOrderDetails[i].serial_number = this.$t('robot.loss')
            } else {
              this.DealOrderDetails[i].serial_number = this.$t('robot.clearance_sale')
            }
          }
          this.DealOrderDetails[i].total = (this.DealOrderDetails[i].price*this.DealOrderDetails[i].size).toFixed(2)
          this.DealOrderDetails[i].coinOne = this.DealOrderDetails[i].user_coin.split('-')[1]
          this.DealOrderDetails[i].coinTwo = this.DealOrderDetails[i].user_coin.split('-')[0]
          this.DealOrderDetails[i].transactionVolume = (this.DealOrderDetails[i].price*this.DealOrderDetails[i].size).toFixed(2)
        }
      }else {
        this.$message.error(res.message)
      }
    },

  },

}
</script>

<style scoped>

</style>
