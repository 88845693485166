<template>
    <div>
      <el-dialog
          v-dialogDrag
          :visible.sync="MartinAddDialog"
          width="510px"
          :show-close="false"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :title="title"
          class="topUpDialog"
          height="304px">
        <Detail_param @closeDetail="closeDetail" :coverConfig="coverConfig" :coverCallback="coverCallback"  ref="detail_paramRef" @openCoverDialog="openCoverDialog" @changeCoverConfig="changeCoverConfig"></Detail_param>
        <span slot="footer" class="dialog-footer">
        <div style="width: 100%;height: 50px;box-sizing: border-box;border-top: 1px solid #EAECEF;cursor: pointer;display: flex" class="footer-hover">
          <div @click="closeDetail" style="border-right: #EAECEF 1px solid;width: 50%;box-sizing: border-box;line-height: 50px;text-align: center;color: #72777E">{{ isAddMartin==1 ? $t('robot.cancel') : isAddMartin==2 ? $t('robot.cancel_reset') : $t('robot.cancel_edit') }}</div>
          <el-button :loading="isSuccess" @click="handleCreateMartin" style="width: 50%;font-size:16px;color:#52C41A;border: 0;border-radius: 0">{{ isAddMartin==1 ? $t('robot.start_') : isAddMartin==2 ? $t('robot.restart_') : $t('robot.save_changes') }}</el-button>
        </div>
      </span>
      </el-dialog>
      <CoverConfig @sentData="sentData" @openDetailDialog="MartinAddDialog=true" ref="coverConfigRef"></CoverConfig>
    </div>
</template>

<script>
import Detail_param from "./Detail_param";
import {screenSize} from "../../../utils/tools";
import CoverConfig from "./CoverConfig";
import {userCreateMartin} from "../../../server/martin";
import {change_url} from "../../../server/robot";
export default {
name: "Martin_add_dialog",
  data(){
    return{
      isAddMartin:1,
      title:this.$t('robot.add_martin'),
      MartinAddDialog:false,
      coverConfig:[],
      coverCallback:[],
      isSuccess:false
    }
  },
  components:{Detail_param,CoverConfig},
  created() {

  },
  methods:{
    //關閉參數詳情
    closeDetail(e=0){
      this.MartinAddDialog=false
      this.$refs.detail_paramRef.showDetail=false
      this.$refs.detail_paramRef.policyTypeValue=''
      this.$refs.detail_paramRef.trading_end_value=''
      this.$store.commit('changeAlterCoverConfigOrCallback',0)
      if (e==1) {
        this.$emit('updateList')
        this.isSuccess=false
      }
      for (let key in this.$refs.detail_paramRef.cycleEffect){
        this.$refs.detail_paramRef.cycleEffect[key]=false
      }
    },
    //打开补仓配置或者补仓回调弹框
    openCoverDialog(e,detailParams){
      this.$refs.coverConfigRef.saveCoverNeedData(detailParams)
      if (e==1) this.$refs.coverConfigRef.coverConfigDialog=true
      if (e==2) this.$refs.coverConfigRef.coverCallbackDialog=true
      this.MartinAddDialog=false
    },
    //用户改变参数导致补仓配置变化为默认配置
    changeCoverConfig(policyTypeValue,trendPeriod,orderNumber){
        this.$refs.coverConfigRef.changeCoverConfig(policyTypeValue,trendPeriod,orderNumber)
    },
  //選擇打開對話框OR手機端頁面
    handleOpenType(alterDetailParam){
      if (alterDetailParam==1){   //添加
        this.isAddMartin=1
        this.title=this.$t('robot.add_martin')
        this.$store.commit('addAlterMartin',1)
      }
      else {
        if (sessionStorage.getItem('martin')==2){
          this.isAddMartin=2
          this.title=this.$t('robot.reset_martin')
          this.$store.commit('addAlterMartin',JSON.parse(alterDetailParam))
        }
        else {
          this.isAddMartin=3
          this.title=this.$t('robot.set_trade')
          this.$store.commit('addAlterMartin',JSON.parse(alterDetailParam))
        }
      }
      if (screenSize(900)) {
        this.MartinAddDialog = true
        this.$nextTick(()=>{
          // if (alterDetailParam=1) this.$refs.detail_paramRef.change_url=sessionStorage.getItem('robotType')==0 ? 'robot' :'okex'
          if (alterDetailParam!=1){
            this.$refs.detail_paramRef.recoverAlterParam()
            this.$refs.coverConfigRef.recoverAlterParam()
            this.$refs.detail_paramRef.change_url=JSON.parse(sessionStorage.getItem('alertDetails')).robot_type==0 ? 'robot' : 'okex'
            this.coverConfig=JSON.parse(JSON.stringify(JSON.parse(alterDetailParam).replenishment_conf_new))
            this.coverCallback=JSON.parse(JSON.stringify(JSON.parse(alterDetailParam).replenishment_callback_new))
            this.$refs.detail_paramRef.confirmSaveAlterOrderNum=JSON.parse(alterDetailParam).billing_quantity-1
          }
          this.$refs.detail_paramRef.getDealCoins()
        })
      }
      else {
        this.$router.push('/robot/martin_add')
      }
    },

  //  生成策略
    handleCreateMartin(){
      this.$refs.detail_paramRef.createMartin()
    },
    //获取补仓配置发送过来的数据
    sentData(coverConfig,coverCallback){
      this.coverConfig=JSON.parse(JSON.stringify(coverConfig))
      this.coverCallback=JSON.parse(JSON.stringify(coverCallback))
    },
  },
  // directives: {
  //   drag: {
  //     // 指令的定义
  //     bind: el => {
  //       let oDiv = el
  //       oDiv.onmousedown = (e) => {
  //         let disX = e.clientX - oDiv.offsetLeft
  //         let disY = e.clientY - oDiv.offsetTop
  //         document.onmousemove = (e) => {
  //           let left = e.clientX - disX
  //           let top = e.clientY - disY
  //           oDiv.style.left = left + 'px'
  //           oDiv.style.top = top + 'px'
  //         }
  //         document.onmouseup = (e) => {
  //           document.onmousemove = null
  //           document.onmouseup = null
  //         }
  //       }
  //     }
  //   }
  // }
}
</script>

<style scoped>

/*>>> .topUpTipDialog .el-dialog__header{*/
/*  padding: 0;*/
/*}*/

>>> .el-dialog__body{
  padding-top: 0;
}
>>> .el-dialog__footer{
  padding: 0;
}
.footer-hover>div:hover{
  background: rgb(238, 239, 241);
}



</style>
